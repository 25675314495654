import {request} from "./request"

export function getList(data){
    return request({
        url:`/gw/list/${data.type}/${data.num}`,
        method: "GET",
    })
}

export function information(data){
    return request({
        url:`/gw/consult`,
        method: "post",
        data:data
    })
}
