<template>
  <div class="home">
    <div class="wrapper">
      <div class="sidebar">
        <div class="clearfix k1120">
          <div class="navBox" id="openSidebar">
            <a href="javascript:void(0)" class="navOpen"></a>
          </div>
          <div id="menu">
            <ul class="clearfix">
              <li>
                <a href="#home" class="nav_home current"><span>首页<br />
										<b>Home</b></span></a>
              </li>

              <li>
                <a href="#case" class="nav_case"><span>产品展示<br />
										<b>Core Range</b></span></a>
              </li>

              <li>
                <a href="http://pc.spirit-empire.com/" class="nav_news"><span>论坛<br />
										<b>Forum</b></span></a>
              </li>
              <li>
                <a href="https://www.angelshare.cn/" class="nav_services"><span> 商城<br />
										<b>
											Shopping Mall</b></span></a>
              </li>
              <li>
                <a href="#about" class="nav_about"><span>关于我们<br />
										<b>About us</b></span></a>
              </li>
              <li>
                <a href="#contact" class="nav_contact"><span>联系我们<br />
										<b>Contact us</b></span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="header">
        <div class="k1120 clearfix"><a href="#home" id="logo">logo</a></div>
      </div>
      <div id="home" class="scrol-page">
        <div class="banner">
          <div class="b_1">
            <div class="k1120 clearfix">
              <div class="b_text">

                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
              </div>
            </div>
          </div>
        </div>


        <div id="case" class="scrol-page">
          <h2 class="title"><span>我们的作品</span></h2>
          <h5 class="infoText">
            酒入豪肠，七分酿成月光，剩余三分啸成剑气袖口一吐，便是半个盛唐。
          </h5>
          <div class="case k1120">
            <ul class="clearfix">
              <li v-for="(item,key) in imageList" :key="key">
                <div class="view-tenth">
                  <img :src="item.url" /><a href="#" class="mask" target="_blank">
                  <h2 v-text="item.name"></h2>
                </a>
                </div>
              </li>

            </ul>
            <div class="tc moreBox">
              <a href="#" target="_blank" class="moreBtn">查看更多</a>
            </div>
          </div>
          <div class="ad_2">
            <h3><span>埃德拉多尔酒厂</span></h3>
            <p>
              在Edradour酒厂可以找到苏格兰最小的威士忌蒸馏器，几乎是苏格兰法定的最小容量。
            </p>
          </div>
        </div>
      </div>
<!--      <div id="news" class="scrol-page">
        <div class="k1120">
          <h2 class="title"><span>论坛</span></h2>
          <h5 class="infoText">
            您可以通过论坛新进一步了解我们。我们会发布与分享一些酒款，无论您喜欢哪种风格的苏格兰威士忌，您一定会找到一瓶您喜欢的。
          </h5>
          <div class="newsList">
            <ul class="clearfix">
              <li v-for="(item,key) in articleList" :key="key" class="list">
                <div class="newsListBox">
                  <h4>
                    <a target="_blank" v-text="item.title"></a>
                  </h4>
                  <div class="newsViewText" v-html="item.content">

                  </div>
                  <p class="newsViewFoot">
                    <a target="_blank" class="newsListView" @click="goDesc(item)">查看详情</a>
                  </p>
                </div>
              </li>

            </ul>
            <div class="tc moreBox">
              <a target="_blank" class="moreBtn">查看更多</a>
            </div>
          </div>
        </div>
        <div class="ab_3">
          <h3>苏格兰单一麦芽威士忌</h3>
          <h5 class="infoText">
            麦芽威士忌是苏格兰的“原始”威士忌。麦芽威士忌仅由大麦麦芽制成，在两个（有时是三个）铜锅蒸馏器中，通过批处理。“单一”麦芽威士忌是个别酿酒厂的产品。
          </h5>

          <p class="moreBox">
            &lt;!&ndash; <a href="#" class="viewBtn">点击咨询</a> &ndash;&gt;
          </p>
        </div>
      </div>-->
      <div id="services" class="scrol-page">
        <h2 class="title"><span>商城</span></h2>
        <h5 class="infoText">
          鲸落于海，星沉于洼，风隐于密林，蝉鸣漏进夏至，想让世间所有温柔住进你眼里。
        </h5>
        <div class="servicesBox">
          <ul class="k1120 clearfix">
            <li>
              <div class="servicesBoxList">
                <div class="servicesBoxIcon">
                  <i class="servicesIcon_1"></i>
                </div>
                <h3>线上兑换酒款</h3>
                <!-- 								<p>-企业形象/产品网站设计开发</p>
                                                <p>-集团官方网站设计开发</p>
                                                <p>-淘宝/天猫店铺视觉设计</p>
                                                <p>-html5+css3</p>
                                                <p>-各类型网站设计和制作</p> -->
              </div>
            </li>
            <li>
              <div class="servicesBoxList">
                <div class="servicesBoxIcon">
                  <i class="servicesIcon_2"></i>
                </div>
                <h3>分享瓶体验酒款</h3>
                <!-- 								<p>-软件视觉设计</p>
                                                <p>-Windows/Mac</p>
                                                <p>-OA系统</p>
                                                <p>-管理系统界面</p>
                                                <p>-其他终端</p> -->
              </div>
            </li>
            <li>
              <div class="servicesBoxList">
                <div class="servicesBoxIcon">
                  <i class="servicesIcon_3"></i>
                </div>
                <h3>生日包桶定制</h3>
                <!-- 								<p>-Logo设计</p>
                                                <p>-平面设计</p>
                                                <p>-视觉识别系统设计</p>
                                                <p>-画册设计</p> -->
              </div>
            </li>
            <li>
              <div class="servicesBoxList">
                <div class="servicesBoxIcon">
                  <i class="servicesIcon_4"></i>
                </div>
                <h3>其它增值服务</h3>
                <!-- 								<p>-网站维护与管理</p>
                                                <p>-域名注册</p>
                                                <p>-服务器租赁</p> -->
              </div>
            </li>
          </ul>
        </div>
        <div class="ad_1">
          <div class="ad_1Text">
            省心&nbsp;&nbsp;·&nbsp;&nbsp;放心&nbsp;&nbsp;·&nbsp;&nbsp;专心
          </div>
        </div>
      </div>

      <div id="about" class="scrol-page">
        <h2 class="title"><span>我们的自述</span></h2>
        <div class="k1120">
          <div class="aboutBox">
            <h4>古仓酒业股份有限公司</h4>
            <p>
              成立于2011年的厦门古仓酒业股份有限公司（简称：古仓酒业），是一家专业从事苏格兰单一麦芽威士忌的进口及分销企业。
            </p>
            <p>
              作为国内单一麦芽威士忌领域最早的拓荒人，从业十余年间，古仓酒业始终秉承着“弘扬天下名酒文化，传承健康饮酒之道”的使命，为国内威士忌爱好者引进众多质量上乘，品质卓越的苏格兰单一麦芽威士忌。
            </p>
            <p>
              在古仓酒业的不断努力下，越来越多的人了解到苏格兰单一麦芽威士忌的迷人魅力，也有越来越多的苏格兰威士忌品牌被人们所熟知。时至今日，古仓酒业仍代理包括高登&麦克菲尔、圣弗力、埃德拉多尔等一众苏格兰最具影响力的威士忌品牌。
            </p>
            <p>
              除此之外，古仓酒业还先后在厦门、北京、上海、福州四座城市建立了以苏格兰单一麦芽威士忌形式呈现的体验馆；在全国十余个城市建立了完善的经销渠道。丰富的藏酒、专业的服务，让古仓成为国内爱好者心中最值得信赖的品牌。
            </p>
          </div>
        </div>
      </div>

      <div id="contact" class="scrol-page">
        <h2 class="title"><span>联系我们</span></h2>
        <div class="infoText">
          世界那么搭，我遇到了你，你也遇到了我，真好。
        </div>
        <div class="contactBox">

          <div class="mapBox">
            <div class="contactFoot">
              <div class="clearfix k1120">
                <dl>
                  <dt>门店地址</dt>
                  <dd>
                    <p>北京 北京市朝阳区建外SOHO西区11号楼1506</p>
                    <p>上海 上海市黄浦区南京东路139号美伦大楼四楼M405</p>
                    <p>厦门 厦门市思明区育秀东路武夷工贸3号楼一层</p>
                    <p>福州 福州市鼓楼区三坊七巷塔巷65号</p>

                  </dd>
                </dl>
               
                <dl>
                  <dt>联系我们</dt>
                  <dd>
            
                    <p>
                      <a tel="0592-5072099" class="telBtn">0592-5072099</a>
                    </p>
                    <p><a class="email">keith@spirit-empire.com</a></p>

                  </dd>
                </dl>
              </div>
            </div>
            <div style="width:100%;" id="dituContent"></div>
          </div>
          <div class="message" style="background: #fff;">
            <div class="k1120 clearfix">
              <div class="messageRow_1">
                <div class="messageBox">
                  <h3>告诉我们您的需求</h3>
                  <h4>在线填写需求，我们将尽快为您答疑解惑。</h4>
                  <p>工作时间：9:00 ~ 18:00</p>
                  <p>工作日（周一至周五）</p>
                </div>
              </div>
              <form action="/plus/diy.php" enctype="multipart/form-data" method="post">
                <input type="hidden" name="action" value="post" />
                <input type="hidden" name="diyid" value="1" />
                <input type="hidden" name="do" value="2" />
                <div class="messageRow_2">
                  <div class="messageBox">
                    <div class="fromList">
                      <input type="text" placeholder="您的姓名" value="" name="full_name"
                             id="full_name" v-model="from.name" />
                    </div>
                    <div class="fromList">
                      <input type="text" placeholder="联系电话" value="" name="mobile" id="mobile"
                             v-model="from.mobile" />
                    </div>
                    <div class="fromList">
                      <input type="text" placeholder="电子邮箱" value="" name="email" id="email"
                             v-model="from.email" />
                    </div>
                    <div class="fromList">
                      <input type="text" placeholder="公司名称" value="" name="company" id="company"
                             v-model="from.company" />
                    </div>
                  </div>
                </div>
                <div class="messageRow_3">
                  <div class="messageBox">
                    <div class="fromList">
											<textarea placeholder="填写详细内容" v-model="from.content" value='' name="msg"
                                                      id="msg"></textarea>
                    </div>
                    <input name="mytime" value="" type="hidden" id="mytime" />
                    <input type="hidden" name="dede_fields"
                           value="full_name,text;mobile,text;email,text;company,text;msg,multitext;mytime,text" />
                    <input type="hidden" name="dede_fieldshash"
                           value="c971c82ee72551f4e9e131a5a98d85bc" />
                    <img @click="submit" style="cursor: pointer;" src="../assets/img/btn.png" />
                    <span class="fromListInfo">我们将在3个工作日内回复，资料会保密处理。</span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="footer"> 厦门总店地址：厦门市思明区育秀东路武夷工贸3号楼一层 <br>
      <a href="https://beian.miit.gov.cn">闽ICP备15025617号-1</a>&nbsp;&nbsp; </div>
  </div>
</template>

<script>
  import {
    getList,
    information
  } from "../api/home"
  export default {
    name: "Home",
    components: {},
    data() {
      return {

        articleList: [],
        imageList: [],
        from: {}
      }
    },
    mounted() {
      var nowDate = new Date();
      var str = nowDate.getFullYear() + "-" + (nowDate.getMonth() + 1) + "-" + nowDate.getDate() + " " + nowDate
              .getHours() + ":" + nowDate.getMinutes() + ":" + nowDate.getSeconds();
      document.getElementById("mytime").value = str;
      this.getList();
    },
    methods: {
      async getList() {
        let res = await getList({
          type: 'image',
          num: 8
        });
        if (res.code == 200) {
          this.imageList = res.data;
          for (let i = 0; i <this.imageList.length ; i++) {
            this.imageList[i].url = "http://www.spirit-empire.com:8080/"+this.imageList[i].url;
          }
        }
        let res2 = await getList({
          type: 'article',
          num: 6
        });
        if (res2.code == 200) {
          this.articleList = res2.data;
        }
      },
      goDesc(item) {
        console.log(item);
      },
      async submit() {
        var reg = /^1[3-9]\d{9}$/
        if (!reg.test(this.from.mobile) && !this.from.name) {
          alert('请正确输入联系方式')
          return;
        }
        let res = await information(this.from);
        if (res.code == 200) {
          alert('提交成功!');
        } else {
          alert('提交失败!');
        }
      }
    }
  };
</script>

<style scoped>

</style>
